import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';

import { RichText } from './styled';
import Container from '../../global/Container';

const Privacy = ({ data }) => {
    return data.text ? (
        <Container narrow>
            <RichText
                dangerouslySetInnerHTML={{
                    __html: marked(data.text),
                }}
            />
        </Container>
    ) : null;
};

Privacy.propTypes = {
    data: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }).isRequired,
};

export default Privacy;
