import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// components
import Layout from '../components/Layout';
import PrivacyComponent from '../components/Pages/Privacy';

// hooks
import useTranslated from '../hooks/useTranslated';

const Privacy = ({ data, ...rest }) => {
    const { translatedData } = useTranslated(data, rest.pageContext);

    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            hasContainer={false}
            showOurCustomers={false}
            showWantToKnowMore={false}
            metatags={translatedData.strapiPrivacy.metatags}
            href="/privacy/"
        >
            <PrivacyComponent data={translatedData.strapiPrivacy} />
        </Layout>
    );
};

Privacy.propTypes = {
    data: PropTypes.shape({
        strapiPrivacy: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default Privacy;

export const query = graphql`
    query PrivacyDe {
        strapiPrivacy {
            metatags {
                description_de
                description_en
                title_de
                title_en
            }
            text_de
            text_en
        }
    }
`;
