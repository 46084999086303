import styled from 'styled-components';

export const RichText = styled.div`
    margin: 80px 0;

    @media all and (max-width: 767px) {
        margin: 3em 0;
    }

    h1 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 24px;
    }
    h3 {
        font-size: 1.875rem;
        font-weight: 600;
        color: #000000;
        margin-bottom: 24px;
    }

    h4 {
        font-size: 1.25rem;
        font-weight: 600;
        color: #000000;
        margin-bottom: 24px;
    }
    h5 {
        font-size: 1rem;
        font-weight: 500;
        color: #000000;
        margin-bottom: 24px;
    }

    h6 {
        font-size: 0.875rem;
        margin-bottom: 24px;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 500;
    }
    p {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(0, 0, 0, 0.64);
        margin-bottom: 24px;

        @media all and (max-width: 768px) {
            font-size: 0.875rem;
            line-height: 24px;
        }
    }

    @media all and (max-width: 1024px) {
        h1 {
            font-size: 1.75rem;
        }

        h2,
        h3,
        h4 {
            font-size: 1.125rem;
        }
    }
`;
